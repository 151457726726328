// extracted by mini-css-extract-plugin
export var bodyBase = "edit-tournament-module--body-base--f0bc8 edit-tournament-module--site-font--27295";
export var bodyLarge = "edit-tournament-module--body-large--979dc edit-tournament-module--body-base--f0bc8 edit-tournament-module--site-font--27295";
export var bodyLargeBold = "edit-tournament-module--body-large-bold--bd84a edit-tournament-module--body-base--f0bc8 edit-tournament-module--site-font--27295";
export var bodyRegular = "edit-tournament-module--body-regular--ff33e edit-tournament-module--body-base--f0bc8 edit-tournament-module--site-font--27295";
export var bodyRegularBold = "edit-tournament-module--body-regular-bold--f2d20 edit-tournament-module--body-base--f0bc8 edit-tournament-module--site-font--27295";
export var bodySmall = "edit-tournament-module--body-small--76eda edit-tournament-module--body-base--f0bc8 edit-tournament-module--site-font--27295";
export var bodySmallBold = "edit-tournament-module--body-small-bold--f298d edit-tournament-module--body-base--f0bc8 edit-tournament-module--site-font--27295";
export var borderTop = "edit-tournament-module--border-top--81d17";
export var breakWordContainer = "edit-tournament-module--break-word-container--25836";
export var buttonIconBase = "edit-tournament-module--button-icon-base--ea6f9";
export var clickLink = "edit-tournament-module--click-link--22009";
export var container = "edit-tournament-module--container--d9f88";
export var dropdownBase = "edit-tournament-module--dropdown-base--3f37f";
export var dropdownSelectBase = "edit-tournament-module--dropdown-select-base--d28f4 edit-tournament-module--text-input--d2599";
export var errorIcon = "edit-tournament-module--error-icon--d7ca2";
export var flexCol = "edit-tournament-module--flex-col--f1b60";
export var formErrorMessage = "edit-tournament-module--form-error-message--cd673";
export var h3 = "edit-tournament-module--h3--59fed";
export var h4 = "edit-tournament-module--h4--8c8a3";
export var hoverLink = "edit-tournament-module--hover-link--916b6";
export var hoverRow = "edit-tournament-module--hover-row--af480";
export var iframe = "edit-tournament-module--iframe--67b7e";
export var membershipContainer = "edit-tournament-module--membership-container--a0ec0 edit-tournament-module--flex-col--f1b60 edit-tournament-module--primary-border--744a6";
export var membershipHeader = "edit-tournament-module--membership-header--088c5";
export var membershipHeading = "edit-tournament-module--membership-heading--eec91";
export var membershipLabel = "edit-tournament-module--membership-label--848ed";
export var moreFiltersBorderClass = "edit-tournament-module--more-filters-border-class--d2bc2";
export var pageBg = "edit-tournament-module--page-bg--631a3";
export var pointer = "edit-tournament-module--pointer--ad92d";
export var primaryBorder = "edit-tournament-module--primary-border--744a6";
export var shadowBoxLight = "edit-tournament-module--shadow-box-light--2adbf";
export var siteFont = "edit-tournament-module--site-font--27295";
export var slideDownAndFade = "edit-tournament-module--slideDownAndFade--5ce6c";
export var slideLeftAndFade = "edit-tournament-module--slideLeftAndFade--e1f62";
export var slideRightAndFade = "edit-tournament-module--slideRightAndFade--aa380";
export var slideUpAndFade = "edit-tournament-module--slideUpAndFade--5b36b";
export var spinner = "edit-tournament-module--spinner--17715";
export var statusDecoration = "edit-tournament-module--status-decoration--27b89";
export var textInput = "edit-tournament-module--text-input--d2599";
export var textInverted = "edit-tournament-module--text-inverted--1b7f0";
export var textMediumDark = "edit-tournament-module--text-medium-dark--19815";
export var tooltipFont = "edit-tournament-module--tooltipFont--7f39c";
export var unstyledButton = "edit-tournament-module--unstyled-button--95226";