import gql from 'graphql-tag';

export const GET_URL_SEGMENT = gql`
  query GetUrlSegment($id: UUID!) {
    tournament(id: $id) {
      id
      organisation {
        id
        urlSegment
      }
    }
  }
`;
