import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import EditTournament from 'src/components/edit-tournament/edit-tournament';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const EditTournamentPage = () => {
  return (
    <Router basepath="/tournaments/edit-tournament">
      <EditTournamentRoute path="/:tournamentId" />
    </Router>
  );
};

interface EditTournamentRouteProps extends RouteComponentProps {
  tournamentId?: string;
}

const EditTournamentRoute: React.FC<EditTournamentRouteProps> = ({ tournamentId }) => {
  return (
    <Layout noPadding>
      <SEO title="Edit Tournament" />
      <PrivateRoute>
        <EditTournament tournamentId={tournamentId} />
      </PrivateRoute>
    </Layout>
  );
};

export default EditTournamentPage;
